import aseni from "@assets/client/aseni.png";
import avicenne from "@assets/client/avicenne.png";
import bethesda from "@assets/client/bethesda.png";
import bfc from "@assets/client/bfc.png";
import bionex from "@assets/client/bionex.jpg";
import cc from "@assets/client/cc.png";
import defense from "@assets/client/defense.png";
import domeau from "@assets/client/domeau.png";
import galana from "@assets/client/galana.png";
import gesco from "@assets/client/gesco.png";
import hary from "@assets/client/hary.png";
import horus from "@assets/client/horus.png";
import iris_medical from "@assets/client/iris_medical.png";
import iris from "@assets/client/iris.png";
import newpack from "@assets/client/newpack.png";
import sagis from "@assets/client/sagis.png";
import iconSupport from "@assets/img/icon_support.svg";
import iconPrice from "@assets/img/icon_price.svg";
import iconRepport from "@assets/img/icon_repport.svg";
import iconProd from "@assets/img/icon_prod.svg";
import albioma from "@assets/client/albioma-logo.png";
import futurmap from "@assets/client/futurmap.png";
import gds from "@assets/client/gds.png";
import runpay from "@assets/client/runpaye_logo.png";
import smarteo from "@assets/client/smarteo.png";
import pamf from "@assets/client/logo_pamf.png";

export const services = {
  options: [
    "Licences Kaseya",
    "Outils de l’entreprise",
    "Support utilisateurs",
    "Gestion des tickets",
    "Contact par téléphone",
    "Contact par mail",
    "Prise en main à distance",
    "Métrique de temps",
    "Rapport mensuel",
  ],
  plus: {
    disable: [1],
    details: [
      "Un service de support et assistance technique de niveau 1 et 2 fourni avec le logiciel de gestion informatique pour les services gérés Kaseya.",
      "Proposé aux entreprises souhaitant externaliser le traitement de leur service de support et qui ont besoin d’une solution de gestion de parc informatique, de ticketing et de prise en main à distance integrée. ",
    ],
  },
  standard: {
    disable: [0],
    details: [
      "Le support standard est un service de support informatique de niveau 1 et 2.",
      "Proposé aux entreprises qui ont déjà leurs solutions pour gérer leur service de support et qui souhaitent uniquement externaliser le service de support informatique en s'appuyant sur les outils déjà en place.",
    ],
  },
  kaseya: [
    "Gestion des tickets",
    "Sécurité optimisée",
    "Automatisation de tâches",
    "Audit et inventaire des systèmes et logiciels",
    "Prise de main à distance sécurisée",
    "Etc...",
  ],
};

export interface FaqSchema {
  q: string;
  r: string;
}

export const FAQS_QUESTIONS: FaqSchema[] = [
  {
    q: "COMMENT FONCTIONNE L’ASSISTANCE SUPPORT ?",
    r: `
      1.	Le support est contacté par mail ou par téléphone (il s’agit de laisser un message téléphonique décrivant le problème). Le message téléphonique est ensuite transmis par mail au support et joint au ticket de support. <br /><br />
      2.	Le support crée un ticket (Kaseya/BMS ou solution du client). L’utilisateur est notifié de la création du ticket, il sera notifié de l’évolution de sa demande jusqu’à la clôture du ticket. <br /><br />
      3.	En fonction de la demande, le support assiste directement l’utilisateur. 
      Dans le cas où le support de niveau 1 est en limite de compétence (sujets métiers, sujet de niveau supérieur ou besoin d’intervention sur site), le ticket est escaladé au service informatique du client (interne ou externe).
      L’utilisateur est notifié de l’escalade du ticket. Le service informatique traitera le ticket jusqu’à sa clôture. <br /><br />
      4.	L’incident est réglé, l’intervenant clôture le ticket. L’utilisateur est notifié et il reçoit une demande d’évaluation de l’intervention. <br /><br />
    `,
  },

  {
    q: "LES SUJETS COMMUNÉMENT TRAITÉS DIRECTEMENT PAR LE SUPPORT",
    r: `
      <div class="my-1"><strong>Sujets d’assistance utilisateur :</strong></div>
      <ul>
        <li>Tris des demandes et orientation direct vers les services concernés (infra, métier, dev…)</li>
        <li>Assistance dans l’usage du poste de travail, problème de navigateur, méconnaissance de l’outil informatique</li>
        <li>Identification d’un problème lié à un élément de sécurité (Firewall, Certificat SSL, authentification Proxy, filtre antivirus…)</li>
        <li>Utilisation ou fonctionnement d’une application</li>
        <li>Réinitialisation de mot de passe</li>
      </ul>
      <div class="my-1"><strong>Sujets d’exploitation/maintenance :</strong></div>
      <ul>
        <li>Déploiement de mises à jour (système ou logiciels)</li>
        <li>Nettoyage automatisé des postes de travail (fichiers temporaires, caches…)</li>
        <li>Autres actions suivant procédure</li>
      </ul>
    `,
  },
  {
    q: "EST-CE QUE LE SERVICE EST DISPONIBLE 24H/24H – 7J/7 ?",
    r: `
      Non, initialement le service est disponible sous 8h x 5jrs (jours ouvrés) <br /><br />
      Pour un besoin étendu, contactez notre service commercial.    
    `,
  },
];

export const Ref_Client = [
  aseni,
  albioma,
  avicenne,
  bethesda,
  bfc,
  bionex,
  cc,
  defense,
  domeau,
  galana,
  gesco,
  gds,
  futurmap,
  hary,
  horus,
  iris_medical,
  iris,
  newpack,
  runpay,
  pamf,
  sagis,
  smarteo,
];

export const Footer_Link = [
  {
    title: "Liens utiles",
    links: [
      {
        to: "/",
        label: "Accueil",
      },
      {
        to: "/services",
        label: "Services",
      },
      {
        to: "/faq",
        label: "FAQ",
      },
    ],
  },
  {
    title: "Informations",
    links: [
      {
        to: "/cgv",
        label: "Conditions générales de vente",
      },
      {
        to: "/mention",
        label: "Mention légale",
      },
    ],
  },
];

export const VALUE_Data = [
  {
    title: "QUALITÉ",
    content:
      "Si les standards ISO sont une référence pour vous, nos méthodes de travail qui tendent à se conformer à ces normes seront adaptées à la vision que vous avez de votre propre organisation. La gestion des demandes de support en ticket, leur suivi, l’escalade, la relation avec l’utilisateur, la clôture du ticket et la mesure de la satisfaction utilisateur en sont un des principes.",
  },
  {
    title: "RÉACTIVITÉ",
    content:
      "Lorsque le service Helpdesk est sollicité, c’est qu’un utilisateur est devant une difficulté, une position stressante et inconfortable. Notre réactivité nous permet d'établir des liens de confiance et de rassurer l’utilisateur en demande. En répondant promptement aux demandes, nous participons à la bonne expérience utilisateur de votre service informatique et renforçons leur satisfaction de parcours dans l’entreprise.",
  },
  {
    title: "COMPÉTENCE",
    content:
      "L’implication de nos collaborateurs et la volonté de toujours donner le meilleur sur chaque action est la définition de l’excellence pour nous. Une formation adaptée et un encadrement expérimenté nous permettent d’afficher une satisfaction utilisateur forte.",
  },
  {
    title: "EXIGENCE",
    content:
      "Pour atteindre le succès, il est essentiel de prendre en compte les exigences, permettant ainsi de définir des objectifs clairs et mesurables pour les deux parties. Ce qui facilite l'évaluation de la réussite du projet et permet de tirer pleinement parti de chaque expérience.",
  },
];

export const Why_Data = [
  {
    icon: iconSupport,
    title: "Support 8/5",
    content:
      "Support disponible 8 heures par jour,<br/> 5 jours par semaine et plus si besoin",
  },
  {
    icon: iconPrice,
    title: "Coût réduit",
    content:
      "La différence des coûts liés à la ressource humaine à Madagascar vous est largement favorable.",
  },
  {
    icon: iconRepport,
    title: "Suivi et rapport",
    content:
      "Chaque début de mois, nous fournissons à nos clients un rapport qui leur permet d'avoir une vue d'ensemble sur les activités du support.",
  },
  {
    icon: iconProd,
    title: "Production",
    content:
      "Grâce à la réactivité de notre équipe, nous réduisons considérablement les temps de demande d'assistance et accelerons l'escalade eventuelle du ticket à vos ingenieurs.",
  },
];
