import { Title } from "@components/Typography";
import { FC, ReactNode } from "react";

interface BoxProps {
  title: ReactNode;
  children: ReactNode;
  className?: string;
}

const Box: FC<BoxProps> = ({ title, children, className }) => {
  return (
    <div className={className}>
      <Title Tag="h3">{title}</Title>
      <div className="mt-[23px]">{children}</div>
    </div>
  );
};

export default Box;
