import { FC, ReactNode } from "react";

interface CircleProps {
  children: ReactNode;
  size?: number;
  className?: string;
  bg?: string;
}

const Circle: FC<CircleProps> = ({ children, className, size = 40, bg }) => {
  return (
    <div
      style={{ width: size, height: size, backgroundColor: bg }}
      className={`rounded-full flex justify-center items-center ${className}`}
    >
      {children}
    </div>
  );
};

export default Circle;
