import Button from "@components/Button";
import Logo from "@components/Logo";
import { FC, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import phone from "@assets/img/phone.png";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { IconMenu2 } from "@tabler/icons-react";
import logo from "@assets/img/head-logo.svg";

const Navbar: FC = () => {
  const [collapse, setCollapse] = useState<boolean>(true);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  document.addEventListener("wheel", (e: any) => {
    const nav = document.getElementById("navbar");
    const pos = document.getElementsByTagName("html")[0].scrollTop;
    if (e.target?.tagName !== "TEXTAREA") {
      if (pos > 800) {
        if (e.deltaY < 0) {
          nav?.classList.add(
            "animate-drop",
            "sticky",
            "top-0",
            "shadow-md",
            "z-20"
          );
        }
        if (e.deltaY > 0) {
          if (nav?.classList.toString().includes("animate-drop")) {
            nav.classList.replace("animate-drop", "animate-up");
            setTimeout(() => {
              nav.classList.remove(
                "animate-up",
                "sticky",
                "top-0",
                "shadow-md",
                "z-20"
              );
            }, 200);
          }
        }
      }

      if (pos < 800) {
        nav?.classList.remove(
          "animate-up",
          "sticky",
          "top-0",
          "shadow-md",
          "z-20"
        );
      }
    }
  });
  return (
    <>
      <div
        id="navbar"
        className="bg-gradient-to-r from-white from-30%  to-[#00D4FE] px-4 md:px-[50px] xl:px-[100px] h-[90px] flex items-center justify-between rounded-none z-30"
      >
        <div>
          <Logo className="w-[130px] lg:w-[180px]" src={logo} to="/" />
        </div>
        <div className="hidden md:flex items-center gap-[70px] xl:gap-[133px] xl:text-[18px] relative z-20">
          <u className="list-none no-underline flex gap-[80px] xl:gap-[107px]">
            <li>
              <NavLink
                to="/"
                className={({ isActive }) => {
                  return [
                    isActive ? "text-black font-medium" : "font-normal",
                  ].join(" ");
                }}
              >
                Accueil
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/services"
                className={({ isActive }) => {
                  return [
                    isActive ? "text-black font-medium" : "font-normal",
                  ].join(" ");
                }}
              >
                Services
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/faq"
                className={({ isActive }) => {
                  return [
                    isActive ? "text-black font-medium" : "font-normal",
                  ].join(" ");
                }}
              >
                FAQ
              </NavLink>
            </li>
          </u>
          <Button
            className="bg-white text-primary"
            icon={<img src={phone} alt="" />}
            onClick={() => {
              if (pathname === "/") {
                const elem = document.getElementById("contact");
                elem?.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                  inline: "nearest",
                });
              } else {
                navigate("/#contact");
              }
            }}
          >
            Contactez-nous
          </Button>
        </div>
        {collapse && (
          <IconMenu2
            color="#ffffff"
            className="block md:hidden z-50"
            onClick={() => setCollapse(false)}
          />
        )}
      </div>
      <div className="block md:hidden fixed top-0 z-50">
        <Sidebar collapsed={collapse} collapsedWidth="0" className="">
          <div className="flex justify-between items-center bg-white rounded-none px-4 p-3 border-b border-b-gray-100">
            <Logo className="w-[100px] mt-2" src={logo} to="/" />
          </div>
          <Menu
            rootStyles={{ backgroundColor: "#fff" }}
            className="h-screen !rounded-none"
          >
            <MenuItem component={<Link to="/" />}>Acceuil</MenuItem>
            <MenuItem component={<Link to="/services" />}>Service</MenuItem>
            <MenuItem component={<Link to="/faq" />}>FAQ</MenuItem>
            <MenuItem component={<Link to="/cgv" />}>CGV</MenuItem>
            <MenuItem component={<Link to="/mention" />}>
              Mention légale
            </MenuItem>
          </Menu>
        </Sidebar>
        {!collapse && (
          <div
            className="fixed top-0 w-screen h-screen bg-black bg-opacity-40"
            onClick={() => setCollapse(true)}
          ></div>
        )}
      </div>
    </>
  );
};

export default Navbar;
