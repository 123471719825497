import { Title } from "@components/Typography";
import { FC } from "react";
import illustr from "@assets/img/faq.svg";
import { FAQS_QUESTIONS } from "@utils/constant";
import FaqBox from "@containers/FaqBox";
import Seo from "@utils/Seo";

const Faq: FC = () => {
  return (
    <>
      <Seo title="FAQ" />
      <div className="p-5 md:p-[5rem]  max-w-[1400px] m-auto">
        <div className="">
          <Title Tag="h3">Foire Aux Questions</Title>
          <p className="max-w-[500px] mt-3">
            Bienvenue dans notre Foire Aux Questions (FAQ) ! <br /> Nous avons
            rassemblé ici les questions les plus fréquemment posées par nos
            clients. Nous espérons que cela vous aidera à obtenir les réponses
            que vous cherchez.
          </p>
        </div>
        <div className="flex flex-col-reverse md:grid md:grid-cols-2 items-center m-5 gap-5">
          <div className="flex flex-col justify-end h-full w-fit m-auto gap-5">
            <FaqBox data={FAQS_QUESTIONS.slice(0, 2)} />
            <div className="block sm:hidden">
              <FaqBox data={FAQS_QUESTIONS.slice(2, 3)} start={3} />
            </div>
          </div>
          <div className="flex flex-col items-center">
            <img src={illustr} alt="" className="max-w-[500px] w-full" />
            <div className="hidden sm:block">
              <FaqBox data={FAQS_QUESTIONS.slice(2, 3)} start={3} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
