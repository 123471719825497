import Circle from "@components/Circle";
import { FaqSchema } from "@utils/constant";
import { FC } from "react";

interface FaqBoxProps {
  data: FaqSchema[];
  start?: number;
}

const FaqBox: FC<FaqBoxProps> = ({ data, start = 1 }) => {
  return (
    <>
      {data.map((qst, i) => {
        return (
          <div key={i} className="mb-[34px]">
            <div className="flex">
              <div>
                <Circle
                  size={30}
                  className="bg-[#EEEBFF] text-[#5540BF] inter text-[12px] font-semibold"
                >
                  {("0" + (start + i)).slice(-2)}
                </Circle>
              </div>
              <div className="max-w-[450px] ml-[15px] mt-1">
                <p className="inter font-semibold mb-[20px]">{qst.q}</p>
                <div
                  className="inter text-[13px]"
                  dangerouslySetInnerHTML={{ __html: qst.r }}
                ></div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default FaqBox;
