import { FC } from "react";

interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label: string;
  inputClass?: string;
  textarea?: boolean;
}

const Input: FC<InputProps> = ({
  type = "text",
  label,
  value,
  onChange,
  required,
  className,
  inputClass,
  textarea = false,
  placeholder,
}) => {
  return (
    <div className={`flex flex-col ${className}`}>
      <div className="flex justify-between mx-4">
        <label className="inter text-[12px] text-white">{label}</label>
        {textarea && (
          <p className="inter text-[12px] text-white ml-4">
            {value?.toString().length}/250
          </p>
        )}
      </div>

      {!textarea ? (
        <input
          className={`outline-none bg-[#ffffff50] h-[40px] rounded-[50px] px-4 border-0 w-full text-black ${inputClass}`}
          type={type}
          value={value}
          onChange={onChange}
          required={required}
          placeholder={placeholder}
        />
      ) : (
        <textarea
          className={`outline-none bg-[#ffffff50] h-[40px] rounded-[50px] px-4 border-0 w-full text-black placeholder:font-light placeholder:text-gray-400 hidden-scrollbar  ${inputClass}`}
          onChange={(e: any) => onChange && onChange(e)}
          value={value}
          required={required}
          placeholder={placeholder}
          maxLength={250}
        />
      )}
    </div>
  );
};

export default Input;
