import Logo from "@components/Logo";
import { FC } from "react";
import sep from "@assets/img/about-sep.svg";
import logo from "@assets/img/aveogroup_black.svg";

const About: FC = () => {
  return (
    <div
      id="about"
      className="flex flex-col-reverse lg:flex-row gap-3 justify-around items-center p-3 pl-6 bg-[#EFF5F6] mt-4 md:p-10 md:m-[2.75rem] text-black relative overflow-hidden"
    >
      <div className="w-full lg:w-1/3 text-center lg:text-left z-20">
        <p className="text-[1.7rem] font-bold mb-2">Qui sommes-nous ?</p>
        <p>
          Filiale d’une société française,{" "}
          <strong>
            <a href="https://www.aveolys.fr/" target="_blank" rel="noreferrer">
              AVEOGROUP,{" "}
            </a>
          </strong>
          implantée à Madagascar depuis 2018. Nous proposons un service de
          support Helpdesk à <strong>coût réduit</strong> tout en respectant{" "}
          <strong>la qualité des services</strong> de support, les standards et{" "}
          <strong>les normes internationales</strong>.
        </p>
      </div>

      <div className=" w-1/2 lg:w-[303px] h-fit lg:h-[283px] flex justify-center items-center p-5 z-10">
        <Logo src={logo} to="/" />
      </div>
      <img src={sep} alt="" className="absolute h-full top-0 lg:left-[15vw]" />
    </div>
  );
};

export default About;
