import Button from "@components/Button";
import Stat from "@components/Stat";
import { FC } from "react";
import im1 from "@assets/img/img1.png";
import { Link } from "react-router-dom";
import world from "@assets/img/world.svg";

const Welcome: FC = () => {
  return (
    <div className="min-h-[700px] h-[calc(100vh-90px)] bg-white !rounded-t-none rounded-b-[70px] relative">
      <div className="bg-[#F4FAF9] absolute top-0 left-0 min-h-[calc(100%-100px)] w-full !rounded-t-none rounded-b-[70px] flex flex-col justify-center items-center overflow-hidden">
        <h1 className="font-medium xl:font-[700] text-[2.2rem] md:text-[4rem] xl:text-[5rem] text-center text-black relative z-20">
          Le partenaire de votre <br /> service Helpdesk
        </h1>
        <p className="text-center text-black max-w-[310px] my-3 md:mt-[29px] md:mb-[35px] relative z-20 lg:text-[18px]">
          Service de helpdesk externalisé
        </p>
        <div className="flex flex-col md:flex-row gap-2 md:gap-[110px] relative z-20 mb-[5rem]">
          <Button
            className="bg-white text-primary"
            onClick={() => {
              const elem = document.getElementById("about");
              elem?.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "nearest",
              });
            }}
          >
            Qui sommes-nous?
          </Button>
          <Link to="/services">
            <Button className="text-white bg-primary !border-primary">
              Nos tarifs
            </Button>
          </Link>
        </div>
        <img
          src={world}
          alt=""
          className="absolute top-0 w-full h-full object-cover"
        />
      </div>
      <div className="absolute bottom-0 flex justify-center items-end w-full">
        <div className="h-[100px] hidden lg:flex items-center w-1/5 gap-[78px]">
          <Stat title="+85%" content="Clients satisfaits" />
          <Stat title="3216" content="Tickets traités cette année" />
        </div>
        <picture>
          <img src={im1} alt="" />
        </picture>

        <div className="h-[100px] hidden lg:flex items-center w-1/5 gap-[78px]">
          <Stat title="-15min" content="Temps de reponse" />
          <div className="hidden xl:block">
            <Stat title="Rapport" content="Tous les débuts du mois" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
