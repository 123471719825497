import { FC } from "react";
import { Helmet } from "react-helmet-async";

interface Props {
  title?: string;
  desc?: string;
}

const Seo: FC<Props> = ({ title, desc }) => {
  return (
    <Helmet>
      <title>avéodesk | {title}</title>
      <meta name="description" content={desc} />
      <meta property="og:title" content={`avéodesk | ${title}`} />
      <meta property="og:url" content="https://aveodesk.com/" />
    </Helmet>
  );
};

export default Seo;
