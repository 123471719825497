import { Footer_Link } from "@utils/constant";
import { FC } from "react";
import { Link } from "react-router-dom";
import logo from "@assets/img/aveogroup.svg";

const Footer: FC = () => {
  return (
    <div className="bg-[#021224] flex flex-col justify-between md:items-center p-5 lg:h-[360px] rounded-none">
      <div className="flex flex-col items-center md:items-start md:flex-row gap-10 w-full justify-between max-w-[1200px] md:mt-[4rem]">
        <div className="bg-transparent w-fit h-fit p-3">
          <div className="w-[150px]">
            <a href="https://aveolys.fr" target="_blank" rel="noreferrer">
              <img className="w-full h-full" src={logo} alt="aveolys" />
            </a>
          </div>
        </div>
        {Footer_Link.map((item, i) => {
          return (
            <div key={i} className=" lx:text-[18px]">
              <h4 className="text-white font-medium text-[18px] xl:text-[24px] text-center md:text-left">
                {item.title}
              </h4>
              <ul>
                {item.links.map((link, i) => {
                  return (
                    <li
                      key={i}
                      className="my-2 text-white text-center md:text-left"
                    >
                      <Link to={link.to}>{link.label}</Link>
                    </li>
                  );
                })}
                {i === 1 && (
                  <li
                    key={i}
                    className="my-2 text-white text-center md:text-left"
                  >
                    <a href="/plaquette.pdf" download>
                      Télécharger la plaquette
                    </a>
                  </li>
                )}
              </ul>
            </div>
          );
        })}
        <div className="text-white">
          <h4 className="text-white text-center md:text-left font-medium text-[18px] xl:text-[24px]">
            Contactez-nous
          </h4>
          <canvas
            ref={(node) => {
              if (node) {
                const ctx = node.getContext("2d");
                if (ctx) {
                  ctx.clearRect(0, 0, node.width, node.height);
                  ctx.fillStyle = "#ffffff";
                  ctx.font = "15px Arial";
                  ctx?.fillText("contact@aveolys.com", 0, 20);
                }
              }
            }}
            width={155}
            height={30}
          />
        </div>
      </div>
      <p className="text-sm text-slate-300 text-center my-5">
        &#169; {new Date().getFullYear()} <a href="/">Avéogroup</a> All Rights
        Reserved
      </p>
    </div>
  );
};

export default Footer;
