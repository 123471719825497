import { FC, ReactNode } from "react";
interface TitlePops {
  Tag: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  children: ReactNode;
  className?: string;
  size?: number;
  lh?: number;
  weight?: number;
}
export const Title: FC<TitlePops> = ({ Tag, children, className }) => {
  return (
    <Tag
      className={`font-medium text-[30px] leading-[40px] md:text-[40px] md:leading-[50px] text-black ${className}`}
    >
      {children}
    </Tag>
  );
};
