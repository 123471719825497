import { BrowserRouter } from "react-router-dom";
import Router from "@routes";
import { HelmetProvider } from "react-helmet-async";

const App = () => {
  return (
    <BrowserRouter>
      <HelmetProvider>
        <Router />
      </HelmetProvider>
    </BrowserRouter>
  );
};

export default App;
