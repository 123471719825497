import Button from "@components/Button";
import { Title } from "@components/Typography";
import { Why_Data } from "@utils/constant";
import { FC } from "react";
import { Link } from "react-router-dom";

const Why: FC = () => {
  return (
    <>
      <hr className="w-1/2 m-auto !mt-[10rem] bg-[#ECF0F1]" />
      <div className="py-[2.8rem] px-[2.7rem] min-h-screen flex flex-col lg:flex-row lg:items-center justify-center gap-5 lg:gap-[150px]">
        <div>
          <Title Tag="h3" className="text-black text-[2.75rem]">
            Pourquoi <br className="hidden lg:block" /> nous choisir ?
          </Title>
          <p className="text-black lg:max-w-[20rem] m-auto mt-[0.8rem] mb-[2.8rem]">
            Notre service Helpdesk permet de résoudre rapidement et efficacement
            vos problèmes informatiques et offre également des avantages sur
            d’autres aspects
          </p>
          <Link to="/services">
            <Button className="text-white bg-primary !border-primary">
              Découvrir nos offres
            </Button>
          </Link>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 xl:max-w-[40%]">
          {Why_Data.map((wh, i) => {
            return (
              <div
                key={i}
                className={`${
                  i === 0
                    ? "bg-gradient-to-t from-[#A3E5FA] from-5% via-transparent via-50% to-transparent to-100%"
                    : i === 3 &&
                      "bg-gradient-to-b from-[#A3E5FA] from-5% via-transparent via-50% to-transparent to-100%"
                } p-[2rem] w-full h-full flex flex-col items-center rounded-none`}
              >
                <img className="w-[5rem] h-[5rem]" src={wh.icon} alt="" />
                <h5 className="text-[1.5rem] my-8 font-medium ml-[0.6rem]">
                  {wh.title}
                </h5>
                <p
                  className="text-center"
                  dangerouslySetInnerHTML={{ __html: wh.content }}
                ></p>
              </div>
            );
          })}
        </div>
      </div>
      <hr className="w-1/2 m-auto my-[5rem] bg-[#ECF0F1]" />
    </>
  );
};

export default Why;
