import { Title } from "@components/Typography";
import { FC, useState } from "react";
import { Ref_Client } from "@utils/constant";
import Swipi from "swipi";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";

const Reference: FC = () => {
  const [n, setN] = useState<number>(Math.floor(window.innerWidth / 300));
  window.onresize = () => {
    setN(Math.floor(window.innerWidth / 300));
  };
  return (
    <div className="mt-[11rem] mb-[10rem] overflow-hidden">
      <div className="max-w-[675px] m-[2.75rem]  md:m-auto mb-[3.37rem]">
        <Title Tag="h3">Références</Title>
        <p>Sur différents sujets il nous ont fait confiance.</p>
      </div>
      <div className="m-[2.75rem]">
        <Swipi
          showArrows
          slidesNumber={n}
          autoplay
          autoplaySpeed={2000}
          loop
          prevButton={
            <IconChevronLeft className="m-2 hover:bg-slate-400 hover:stroke-white" />
          }
          nextButton={
            <IconChevronRight className="m-2 hover:bg-slate-400 hover:stroke-white" />
          }
        >
          {Ref_Client.map((ref, i) => {
            return (
              <div
                key={i}
                className="flex h-full items-center justify-center w-full md:mx-[3rem]"
              >
                <img src={ref} alt="" />
              </div>
            );
          })}
        </Swipi>
      </div>
      <hr className="w-1/2 m-auto mt-[5rem]" />
    </div>
  );
};

export default Reference;
