import { FC } from "react";

interface StatProps {
  title: string;
  content: string;
}

const Stat: FC<StatProps> = ({ title, content }) => {
  return (
    <div className=" flex flex-col items-center">
      <p className="text-primary font-bold text-[2rem] leading-[0.9]">
        {title}
      </p>
      <p className="text-primary text-center w-max">{content}</p>
    </div>
  );
};

export default Stat;
