import Button from "@components/Button";
import Input from "@components/Input";
import { FC, useState } from "react";
import Toast, { ToastProps } from "@components/Toast";
import { sendMail } from "@utils/sendMail";
import { IconLoader, IconSend } from "@tabler/icons-react";
import Transition from "@utils/Transition";

interface ContactBoxProps {
  className?: string;
}

const ContactBox: FC<ContactBoxProps> = ({ className }) => {
  const [toast, setToast] = useState<ToastProps>({
    message: undefined,
    type: undefined,
  });
  const [mail, setMail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [progress, setProgress] = useState<boolean>(false);

  const send = () => {
    setProgress(true);
    sendMail(mail, phone, subject, message, (status) => {
      if (status === 200) {
        setToastData("success", "Message envoyé");
        setMail("");
        setSubject("");
        setMessage("");
        setPhone("");
      } else {
        setToastData("error", "Une erreur s'est produite. Veuillez réessayer");
      }
    });
  };

  const setToastData = (type: any, message: any) => {
    setProgress(false);
    setToast({
      type,
      message,
    });
    setTimeout(() => setToast({ type: undefined, message: undefined }), 5000);
  };
  return (
    <div
      id="contact"
      className={`flex flex-col my-[5rem] mb-[10rem] py-[4rem] min-h-[38rem] justify-center items-center  ${className}`}
    >
      <Toast message={toast.message} type={toast.type} />

      <div className="flex flex-col lg:flex-row justify-between items-center w-full">
        <div className="text-center xl:text-left p-[2.7rem] xl:p-[5rem] w-full lg:w-[70%] bg-gradient-to-b from-white to-transparent">
          <h5 className="text-primary text-[2.37rem] mb-[2rem] font-medium">
            Nous contacter :
          </h5>
          <p className="mb-[18px] mt-[29px]">
            Des questions ou des doutes concernant nos services ?
          </p>
          <p className="mb-[18px]">
            Vous avez des commentaires ou des suggestions ?
          </p>
          <p>
            Vous avez besoin de conseils pour choisir le service qui vous
            convient le mieux ?
          </p>
          <p className="mt-[50px]">Nous sommes là pour vous aider !</p>
        </div>
        <div className="bg-gradient-to-b from-primary to-transparent rounded-none flex-grow p-[2.7rem] md:p-[5rem] w-full">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              send();
            }}
            className="flex flex-col gap-2 md:gap-[26px]  !w-full md:w-[25rem]"
          >
            <div className="flex flex-col md:flex-row w-full gap-5 lg:gap-[8rem]">
              <div className="flex-grow">
                <Input
                  label="Votre adresse mail"
                  type="email"
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                  required
                />
              </div>
              <div className="flex-grow">
                <Input
                  label="Votre numero téléphone"
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>

            <Input
              label="Sujet"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
            />
            <Input
              label="Votre message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              inputClass="!min-h-[15vh] !rounded-[15px] !py-1 placeholder:text-orange-50"
              textarea
              placeholder="250 caractères maximum."
            />
            <div className="mt-[12px] mb-[20px]">
              <Button className="bg-white text-primary !w-full md:!w-[200px]">
                <>
                  <div className="mr-3">
                    {progress ? (
                      <Transition animation="rotate" repeat="Infinity">
                        <IconLoader color="#0FB6E1" />
                      </Transition>
                    ) : (
                      <IconSend size={20} color="#0FB6E1" />
                    )}
                  </div>
                  Envoyer
                </>
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactBox;
