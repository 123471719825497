import Layout from "@layout/index";
import Cgv from "@pages/Cgv";
import Faq from "@pages/Faq";
import Home from "@pages/Home";
import Legale from "@pages/Legale";
import Service from "@pages/Service";
import { Route, Routes } from "react-router-dom";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="services" element={<Service />} />
        <Route path="faq" element={<Faq />} />
        <Route path="cgv" element={<Cgv />} />
        <Route path="mention" element={<Legale />} />
      </Route>
    </Routes>
  );
};

export default Router;
