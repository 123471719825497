import Box from "@containers/Box";
import { FC, ReactNode } from "react";
import process from "@assets/img/process.svg";
import Seo from "@utils/Seo";

interface TitlePorps {
  children: ReactNode;
  sub?: boolean;
  num: string;
}

const Title: FC<TitlePorps> = ({ num, sub = false, children }) => {
  return (
    <p className={`${!sub && "mt-5 mb-3"}`}>
      <strong>
        <span className={`${sub ? "mr-5" : "underline mt-5"}`}>
          {!sub && "Article"} {num}
        </span>{" "}
        {children}
      </strong>
    </p>
  );
};

const Cgv: FC = () => {
  return (
    <>
      <Seo title="CGV" />
      <Box
        title="CONDITIONS GÉNÉRALES DE PRESTATIONS DE SERVICES"
        className="p-5 pt-[2rem] md:p-[5rem] max-w-[1400px] m-auto"
      >
        <article className="pb-24">
          <strong>
            ETANT PREALABLEMENT EXPOSE QUE : <br />
          </strong>
          <p>
            Aveogroup est une entreprise de prestations de services
            informatiques. Afin d’assurer une partie de son activité, la société
            a organisé un plateau technique qui porte assistance aux
            utilisateurs et supervise les éléments constituant le système
            d’information.
          </p>
          <p>
            L’offre de prestations de service Helpdesk de l’entreprise Avéocod
            se décline comme suit :
          </p>
          <p className="mt-2">
            1/ Service de Support Helpdesk à deux packs au choix{" "}
          </p>
          <p className="ml-8">
            a- Le pack Support PLUS <br /> b- Le pack Support STANDARD
          </p>
          <br />
          <br />
          <strong>
            IL A ETE CONVENU CE QUI SUIT : <br />
          </strong>
          <Title num="1">– Définitions</Title>
          <p>
            <strong>Client souscripteur </strong> : Nous appellerons « Client
            souscripteur », l’ensemble des sociétés (ESN ou pas) qui auront
            choisi de s’abonner au service Support Helpdesk Externe du
            prestataire. <br />
            <strong>Client utilisateur </strong> : Nous appellerons « client
            utilisateur », l’ensemble des sociétés (ESN ou pas, client
            souscripteur ou pas) dont les utilisateurs pourrons effectuer des
            demandes de support. <br />
            <strong>Client</strong> : Nous parlerons généralement de client,
            lorsque le client souscripteur aura souscrit au service pour son
            propre usage. Le client souscripteur et le client utilisateur seront
            confondus.
            <br />
            <strong>Prestataire</strong> : C’est la société qui propose et
            assure le service de support helpdesk.
            <br />
            <strong>Utilisateur</strong> : Nous appellerons utilisateur, la
            personne physique, utilisatrice d’un outil informatique ayant besoin
            d’une assistance. <br />
            <strong>Service Support Helpdesk</strong> : c’est le service proposé
            par le prestataire pour assister les utilisateurs à résoudre des
            problèmes liés à l’exploitation des ressources informatiques. <br />{" "}
            <strong>Parc</strong> : Il s’agit de l’ensemble des ordinateurs et
            des serveurs continuant le système d’information. <br />
            <strong>Ticket</strong> : C’est la matérialisation dans un logiciel
            spécialisé des demandes d’assistances. <br />
            <strong>Ticket ouvert</strong> : C’est une demande d’assistance
            initialisée en cours de prise en charge. <br />
            <strong>Ticket en cours</strong> : C’est une demande d’assistance
            déjà prise en charge et en cours de traitement.
          </p>
          <Title num="2">– Objet</Title>
          <p>
            Le présent contrat décrit les conditions dans lesquelles le
            prestataire apporte au client souscripteur, le ou les services qu’il
            aura choisis parmi les services de support helpdesk proposés.
          </p>
          <Title num="3">– Services</Title>
          <p>
            Avéocod propose un service de Support Helpdesk sous la forme de deux
            packs distincts.
          </p>
          <br />
          <Title sub num="3.01">
            Le pack Support PLUS
          </Title>
          <p>
            Le pack support plus est à destination des sociétés qui n’ont pas de
            solution de gestion de parc et de tickets helpdesk, ou dont la
            solution est difficilement partageable. Le prestataire inclus
            l’usage de la solution de gestion de parc et d’assistance
            utilisateur qu’il utilise déjà pour son propre système d’information
            et celui d’autres sociétés. Le prestataire pourra enrichir les
            services helpdesk proposés par l’automatisation de processus, la
            génération d’alertes liées au status de certaines ressources, le
            blocage ou la diffusion de certaines application et/ou par
            l’automatisation de rapport d’état du parc.
          </p>
          <br />
          <Title sub num="3.02">
            Le pack Support STANDARD
          </Title>
          <p>
            Le pack standard est à destination des sociétés qui ont déjà des
            outils de gestion de parc et d’helpdesk partageable, sur lesquels
            pourront s’appuyer les intervenants du prestataire. Le logiciel de
            gestion de parc, de gestion de ticket, de prise de main à distance
            et tous autres outils nécessaires à la bonne exécution de la tache
            de support helpdesk à distance sont fournis par le client
            souscripteur et accessibles à distance.
          </p>
          <p>
            Le logiciel de gestion de parc, ticket et tous autres outils
            nécessaires à la bonne exécution de la tache de Support Helpdesk à
            distance sont fournis par le client et accessibles à distance.
          </p>
          <br />
          <Title sub num="3.03">
            Les sujets traités par le support
          </Title>
          <br />
          <strong className="ml-7">Pour le pack support standard :</strong>
          <ul className="list-disc ml-10">
            <li>
              Tris des demandes, traitement des demandes, escalades des demandes
              vers les services concernés (infra, métier, dev, éditeurs).
            </li>
            <li>
              Identification d’un problème lié à un élément de sécurité
              (Firewall, Certificat SSL, authentification Proxy, filtre
              antivirus…)
            </li>
            <li>
              Assistance dans l’usage du poste de travail (problème de
              navigateur, méconnaissance de l’outil informatique...)
            </li>
            <li>Utilisation ou fonctionnement d’une application</li>
            <li>Réinitialisation de mot de passe</li>
            <li>
              Possible déploiement de mise à jour (système ou logiciels) pour
              les packs Support PLUS
            </li>
            <li>
              Possible Nettoyage automatisés des postes de travail (fichiers
              temporaire, caches…) pour les packs Support PLUS
            </li>
            <li>Autres actions suivant procédure</li>
          </ul>
          <br />
          <strong className="ml-7">Pour le pack support PLUS :</strong>
          <ul className="list-disc ml-10">
            <li>L’ensemble des sujets du pack standard</li>
            <li>Possible déploiement de mise à jour (système ou logiciels)</li>
            <li>
              Possible Nettoyage automatisés des postes de travail (fichiers
              temporaire, caches…)
            </li>
            <li>Possible alarme sur service système en défaut</li>
            <li>
              Possible alarme sur équipement, service, site web ne répondant
              plus dans le réseau
            </li>
            <li>Possible blocage de certaines applications</li>
          </ul>
          <br />
          <br />
          <br />
          <br />
          <Title num="4">– Organisation des prestations</Title>
          <p>
            Le service support consiste à assister les utilisateurs ou résoudre
            des problèmes liés à l’exploitation / utilisations des ressources
            informatiques. Lorsqu’une demande d’assistance le nécessite, pour
            des raisons de changement de périmètres, de limite de compétence,
            d’intervention physique ou simplement de choix du client
            souscripteur, elle sera escaladée aux niveaux supérieurs chez le
            client.
          </p>
          <br />
          <Title sub num="4.01">
            - Lieu d’exécution
          </Title>
          <p>
            Le service de support helpdesk fonctionne complètement à distance
            sans aucune intervention directe sur site. Toute action qui
            nécessiterait une présence physique sera à charge du client
            souscripteur.
          </p>
          <br />
          <Title sub num="4.02">
            - Personnel - Horaire d’exécution
          </Title>
          <p>
            Le service de support helpdesk est disponible aux heures et jours
            indiqués dans les conditions particulières. Les demandes parvenant
            en dehors des périodes de disponibilité décrites dans les conditions
            particulières seront prises en compte à l’ouverture de la prochaine
            période de disponibilité.
          </p>
          <br />
          <Title sub num="4.03">
            - Procédure de traitement d’une demande de support
          </Title>
          <div className="my-4">
            <img src={process} alt="" className="max-w-[700px]" />
          </div>
          <br />
          <br />
          <Title num="5">– Prix et facturation</Title>
          <Title sub num="5.01">
            - Prix
          </Title>
          <p>
            Le Client souscripteur accepte de payer au prestataire le prix
            indiqué dans les factures et décrit dans les conditions
            particulières, plus toutes les taxes applicables.
          </p>
          <br />
          <Title sub num="5.02">
            - Facturation
          </Title>
          <p>
            Toute facture du prestataire est envoyée au client souscripteur à
            l'adresse indiquée dans les conditions particulières ou à toute
            adresse que le client souscripteur a communiqué au prestataire après
            la signature du présent contrat.
            <br /> Si le montant des mensualités est inférieur à 50 € HT ou à
            son équivalent en Ariary, la facturation sera annuelle.
            <br /> La facturation mensuelle est effectuée chaque 1er du mois,
            payable à 30 jours à la réception de la facture ou sous 40 jours par
            prélèvement. Dans le cas où la prestation est facturée à l’heure, le
            taux définit dans les conditions particulières sera appliqué au
            forfait temps définit dans ces mêmes conditions particulières. Les
            éventuels dépassements de temps seront majorés de 20%.
          </p>
          <br />
          <Title sub num="5.03">
            - Retard de paiement
          </Title>
          <p>
            En cas de retard de paiement, une indemnité forfaitaire de 40.00 €
            ou à son équivalent en Ariary pour frais de recouvrement est due au
            créancier (Décret n°2012-1115 du 2 octobre 2012 du code du commerce
            Français) dès le premier jour de retard. Cette même règle sera
            appliquée aux clients souscripteur concernés, quel que soit leur
            lieu de résidence et le droit applicable. De plus, à compter du
            trente et unième jour de la facture en souffrance (quel que soit
            l’entreprise membre du groupe concernée), le prestataire sera
            habilité à suspendre l’exécution des travaux prévus par les
            présentes, jusqu’au règlement de ladite facture en souffrance, sans
            que cette suspension puisse être considérée comme une résiliation du
            contrat du prestataire, nonobstant tous dommages et intérêts
            auxquels pourra prétendre le prestataire du fait du non-paiement en
            cause et des préjudices subséquents.
          </p>
          <br />
          <p>
            En cas de désaccord sur une partie de la facture, le client
            souscripteur s’oblige à payer sans retard la partie non contestée.
          </p>
          <br />
          <p>
            Dans le cas d’un rejet du prélèvement automatique, le prélèvement
            sera programmé le mois suivant, se cumulant ainsi à la mensualité
            normale. Des frais d’un montant de 35 € ou à son équivalent en
            Ariary seront appliqués pour frais de dossiers.
          </p>
          <br />
          <p>
            Le non-paiement d’une ou plusieurs mensualités entrainera la
            suspension du contrat, sans pour autant mettre un terme à ce
            dernier, les sommes à devoir restant dues jusqu’au terme de la
            période de 12 mois concernée.
          </p>
          <Title num="6">- Dispositions particulières </Title>
          <p>
            Chacune des parties reconnaît que la personne qu'elle désigne dans
            les spécifications ou conditions particulières, la représente et a
            toute autorité pour poser les actes, prendre les décisions et donner
            les autorisations requises en vue de l'exécution du présent contrat.
          </p>
          <p className="my-4">
            Dans le cas où le prestataire rencontre une impossibilité de
            réaliser le service pour une raison indépendante de sa volonté tel
            qu’une indisponibilité de liaison réseau ou une coupure d’énergie à
            quelque niveau que ce soit dans la chaine de liaison, le client
            reconnait et accepte que des délais plus longs soient nécessaires à
            la réalisation de la prestation.
          </p>
          <p className="my-4">
            Dans le cas où le prestataire considérerait qu’une action demandée
            par le client souscripteur ou par le client utilisateur représente
            un risque pour l’une ou l’autre des parties, il pourra refuser
            d’effectuer ladite action, sans pour autant que cela puisse être
            considéré comme une faute ou manquement au service assuré par le
            prestataire.
          </p>
          <p className="my-4">
            Le client souscripteur s’engage à payer au prestataire le prix
            convenu dans les délais précisés par le contrat. Il s’engage
            également à mettre tout en œuvre pour permettre au prestataire
            d’assurer sa prestation.
          </p>
          <br />
          <Title num="8">- Revente et sous-traitance </Title>
          <p>
            Le prestataire autorise le client souscripteur à souscrire au
            service support plus ou au service support standard pour le proposer
            à ses propres clients. Les propres clients du clients souscripteur
            seront alors identifié comme client utilisateurs. Pour ce faire, le
            client souscripteur doit contractualiser avec ses propres clients
            suivant ses propres conditions financières. Les conditions générales
            ou particulières de cette prestation ne pourront en aucun cas être
            incompatible avec les présentes et son périmètre sera
            obligatoirement contenu dans celui des présentes.
          </p>
          <br />
          <p>
            Le client souscripteur communiquera au prestataires l’identité du
            client utilisateurs. Le prestaire assurera complètement la
            prestation vendue au client souscripteur pour le compte du client
            utilisateur. Le prestataire s’interdira d’effectuer toute approche
            commerciale auprès du client utilisateurs. Le client souscripteur
            respectera ses obligations contractuelles auprès du prestataires. Le
            prestataire sera déchargé de toutes responsabilité à l’égard du
            client utilisateur qui sortiraient du périmètre de la prestation
            vendue au client souscripteur. Si le prestataire devait suspendre sa
            prestation pour le client souscripteur pour des raisons liées au
            defaut de paiement des prestations commandées, le client
            souscripteur ferait son affaire de l’impact de la suspension des
            services avec effet chez un client utilisateurs sans que la
            responsabilité du prestataire ne puisse être engagée.
          </p>
          <br />
          <Title num="8">- Durée du contrat</Title>
          <p className="my-4">
            Le présent contrat est tacitement reconductible. Il pourra néanmoins
            être résilié par l’une ou l’autre des parties à la date anniversaire
            du contrat, sous réserve du respect d’un préavis de trois mois,
            notifié à l’autre par courrier avec accusé de réception. Cependant,
            aucun préavis n’aura à être respecté en cas de force majeure ou
            manquement grave dûment justifiée à l’exécution des obligations
            réciproques prévues au présent contrat.
          </p>
          <p className="my-4">
            Le défaut de paiement de tout ou partie des sommes dues au
            prestataire est considéré comme un manquement grave à l’exécution
            des obligations du client souscripteur et pourra donner lieu à
            résiliation du présent contrat sans préavis. La résiliation pour
            défaut de paiement entrainera une suspension immédiate des services
            et prestations du prestataire, ne dispensera pas le client
            souscripteur de régulariser les factures émises et obligera le
            client souscripteur à des pénalités d’un montant égales aux sommes
            normalement dues jusqu'à la prochaine date anniversaire du contrat.
          </p>
          <p className="my-4">
            Le contrat de service support helpdesk est souscrit pour une durée
            initiale de 12 mois fermes. Le fait que le prestataire ne dénonce
            pas le non-respect de telle clause du contrat par le client
            souscripteur ne peut être interprété comme une novation du contrat.
          </p>
          <p className="my-4">
            Toute dénonciation à invoquer la violation d’une clause quelconque
            du présent contrat ne pourra constituer une renonciation à invoquer
            des violations antérieures, simultanées ou postérieures de la même
            ou d’autres clauses. Une telle dénonciation n’aura d’effet que si
            elle est exprimée par écrit. Au cas où l’une quelconque des clauses
            du présent contrat serait déclarée contraire à la loi ou de toute
            manière inexécutable, cette clause serait supprimée, mais sans qu’il
            en résulte la nullité de tout contrat dont toutes les autres clauses
            demeureraient en vigueur.
          </p>
          <Title num="9">- Litiges</Title>
          <p>
            Tout litige relatif à l’exécution de nos prestations, même en cas de
            recours en garantie ou de pluralité de défendeurs, sera, à défaut
            d’accord amiable ou de dérogation expresse mentionnée en conditions
            particulières, de la compétence du tribunal dont dépend le siège du
            prestataire.
          </p>
          <p>
            Cette clause d’élection de compétence, par accord exprès des
            parties, s’applique même en cas de référé.
          </p>

          <Title num="10">- Confidentialité</Title>
          <p>
            Chacune des Parties s'oblige, en ce qui concerne les informations de
            l’autre Partie dont elle peut avoir connaissance dans le cadre de la
            négociation et de l'exécution du présent contrat, dès lors que ces
            informations ont un caractère sensible notamment sur un plan
            financier, déontologique, économique, technique, commercial, ou
            qu’elles sont déclarées comme telles par l’autre Partie à :
          </p>
          <ul className="list-disc ml-10">
            <li>
              Les garder strictement confidentielles et ne pas les communiquer à
              des tiers, sauf aux fins strictement nécessaires à la bonne
              exécution du contrat,
            </li>
            <li>
              S’abstenir de les exploiter, directement ou indirectement, ou
              permettre leur exploitation par un tiers sous leur contrôle, à
              toute fin autre que la bonne exécution du contrat.
            </li>
            <li>
              Chacune des Parties s'engage à veiller au respect, par ses
              collaborateurs et ses fournisseurs éventuels, de cet engagement de
              confidentialité.
            </li>
            <li>
              La présente obligation de confidentialité cesse trois (3) ans
              après la fin des relations contractuelles.
            </li>
          </ul>
          <Title num="11">- Protection des données personnelles</Title>
          <p className="my-4">
            Conformément aux dispositions de la loi n° 2018-493 du 20 juin 2018
            relative à la protection des données personnelles, Le prestataire
            met en œuvre des procédures spécifiques dans le but de protéger les
            données personnelles confiées par le client souscripteur ou
            collectées par ses propres soins. Les mesures de protection doivent
            empêcher l'accès aux données aux personnes non autorisées. Elles
            doivent également interdire toute atteinte à l'intégrité et/ou à la
            sincérité des informations personnelles détenues et/ou manipulées
            par Le prestataire et/ou par un de ses sous-traitants.
          </p>
          <p className="my-4">
            Le prestataire garantit aux personnes concernées par le traitement
            et entrant dans le champ d'application de la loi n° 2018-493 du 20
            juin 2018 un droit d'accès, de vérification et de rectification aux
            données les concernant. Ces droits sont assortis d'un droit de
            suppression et d'un droit à la portabilité des données sous
            conditions prévues par la loi.
          </p>
          <p className="my-4">
            Le prestataire ne travaillant pas sur des données personnelles
            collectées par elle-même, les données personnelles pouvant servir
            aux travaux commandés par le client souscripteur sont nécessairement
            fournis par ce dernier et sous son entière responsabilité.
          </p>
          <Title num="12">- Responsabilité</Title>
          <p className="my-4">
            Le prestataire dans l’exécution des prestations est tenu à une
            obligation de moyens.
          </p>
          <p className="my-4">
            D'une manière générale, Le prestataire s'engage à exécuter les
            prestations qui lui sont confiées de manière professionnelle. Il
            appartiendra au client souscripteur d'apporter la preuve des
            défaillances qu'il constatera.
          </p>
          <p className="my-4">
            Il est expressément convenu que la responsabilité du prestataire
            sera limitée, quelle que soit la nature et le fondement juridique de
            toute action engagée à son encontre, au prix payé par le client
            souscripteur au titre de la commande relative aux prestations ayant
            entraîné le préjudice.
          </p>
          <p className="my-4">
            Le prestataire n’assume aucune responsabilité ou garantie sur les
            logiciels fournis par des tiers au contrat.
          </p>
        </article>
      </Box>
    </>
  );
};

export default Cgv;
