import { IconExclamationCircle, IconSend } from "@tabler/icons-react";
import Transition from "@utils/Transition";
import { FC } from "react";

export interface ToastProps {
  message: string | undefined;
  type: "error" | "success" | "warning" | undefined;
}

const Toast: FC<ToastProps> = ({ message, type }) => {
  return (
    <>
      {message && (
        <Transition className="fixed top-9 right-5 w-full flex justify-center z-50 animate-drop">
          <div
            id="toast-simple"
            className="flex items-center w-fit max-w-xs p-4 space-x-4 text-gray-500 bg-white rounded-lg shadow"
            role="alert"
          >
            {type === "success" && <IconSend color="#0FB6E1" />}
            {type === "error" && <IconExclamationCircle color="#ffdb00" />}
            <div className="pl-4 text-sm font-normal">{message}</div>
          </div>
        </Transition>
      )}
    </>
  );
};

export default Toast;
