import { FC, ReactNode } from "react";

interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children: ReactNode;
  variant?: "primary" | "secondary";
  bordered?: boolean;
  className?: string;
  icon?: ReactNode;
}

const Button: FC<ButtonProps> = ({ children, onClick, className, icon }) => {
  return (
    <button
      onClick={(e) => onClick && onClick(e)}
      className={`w-full md:w-auto flex items-center justify-center py-[8px] px-[25px] rounded-[10px] shadow-[2px_4px_10px_0px_rgba(0,0,0,0.10)] !border-[2px] border-white ${className}`}
    >
      {icon && <span className="mr-[10px] w-[16px]">{icon}</span>}
      {children}
    </button>
  );
};

export default Button;
