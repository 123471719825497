import { Title } from "@components/Typography";
import ValueItem from "@components/ValueItem";
import { VALUE_Data } from "@utils/constant";
import { FC } from "react";

const Value: FC = () => {
  return (
    <div className="m-[2.75rem] mt-[10rem]">
      <div className="max-w-[700px] m-auto mb-[3.37rem]">
        <Title Tag="h3">Nos valeurs</Title>
        <p>
          Nous mettons en avant le respect de l'éthique profesionnelle et
          l'empathie envers les utilisateurs.
        </p>
      </div>
      <div className="bg-white p-4  lg:py-[4rem] m-auto">
        <div className="m-auto grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-10 xl:gap-y-[5rem] p-4 lg:max-w-[75%]">
          {VALUE_Data.map((val, i) => {
            return (
              <div key={i} className="m-auto h-full">
                <ValueItem key={i} title={val.title} content={val.content} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Value;
