import { FC } from "react";

interface LogoProps {
  className?: string;
  src: string;
  to: string;
}

const Logo: FC<LogoProps> = ({ className, src, to }) => {
  return (
    <div className={className}>
      <a href={to}>
        <img className="w-full h-full" src={src} alt="aveocod" />
      </a>
    </div>
  );
};

export default Logo;
