import { FC } from "react";
import im1 from "@assets/img/puce.svg";

interface ValueItemProps {
  title: string;
  content: string;
}

const ValueItem: FC<ValueItemProps> = ({ title, content }) => {
  return (
    <div className="max-w-[400px] shadow-[0px_0px_10px_0px_rgba(0,0,0,0.05)] overflow-hidden h-full">
      <div className="bg-primary flex items-center rounded-none p-2">
        <img src={im1} alt="" className="w-[24px]" />
        <h3 className="font-medium text-white md:text-[24px] ml-[0.8rem]">
          {title}
        </h3>
      </div>
      <div className="bg-white p-3">
        <p className="text-[13px] leading-5 inter">{content}</p>
      </div>
    </div>
  );
};

export default ValueItem;
