import Footer from "@containers/Footer";
import Navbar from "@containers/NavBar";
import Transition from "@utils/Transition";
import { FC, createContext, useEffect, useState } from "react";
import loader from "@assets/img/loader.png";
import { Outlet, useLocation } from "react-router-dom";

export const IndexContext = createContext<any>(null);

const Layout: FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const { hash } = useLocation();

  useEffect(() => {
    if (hash !== "") {
      const elem = document.getElementById(hash.substring(1));
      elem?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    setTimeout(() => {
      setLoading(false);
    }, 900);
  }, [hash, loading]);
  return (
    <div className="relative">
      {loading ? (
        <div className="flex justify-center items-center w-screen h-screen">
          <img src={loader} alt="" className="animate-spin" />
        </div>
      ) : (
        <>
          <Transition duration={0.5}>
            <Navbar />
            <Outlet />
            <Footer />
          </Transition>
        </>
      )}
    </div>
  );
};

export default Layout;
