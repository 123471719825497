import Box from "@containers/Box";
import Seo from "@utils/Seo";
import { FC, ReactNode } from "react";

interface SectionProps {
  children: ReactNode;
  title: string;
}

const Section: FC<SectionProps> = ({ children, title }) => {
  return (
    <div className="mb-5">
      <p>
        <strong>{title}</strong>
      </p>
      <p>{children}</p>
    </div>
  );
};

const Legale: FC = () => {
  return (
    <>
      <Seo title="Mention légale" />
      <Box
        title="Mention légale"
        className="p-5 pt-[2rem] md:p-[5rem] max-w-[1400px] m-auto"
      >
        <article className="pb-24">
          <Section title="Identification du site">
            Les informations suivantes sont fournies en vertu de l’Article 6 de
            la loi n° 2004-575 du 21 juin 2004 pour la Confiance dans l’Economie
            Numérique.
          </Section>
          <Section title="Le site est édité par">La société AVEOGROUP</Section>
          <Section title="Siège social">
            Immeuble ARO, 1er Etg, Esc B, Porte 104 Ampefiloha - Antananarivo
            101
          </Section>
          <Section title="RCS">RCS Antananarivo 2018 B 00252 </Section>
          <Section title="Directeur de publication">Patrick Hervier</Section>
          <Section title="Hébergeur">
            AVEOGROUP utilise le service d’hébergement d’OVH pour le présent
            site.
          </Section>
          <Section title="Conception du site">AVEOGROUP</Section>
          <Section title="Politique de confidentialité">
            Le 25 mai 2018, la nouvelle législation européenne sur la protection
            des données entre en vigueur. C’est le Règlement Général sur la
            Protection des Données soit RGPD.
            <br />
            Ce nouveau règlement encadre plus strictement l’utilisation par les
            entreprises des données à caractère personnel.
            <br />
            Conscient de l’importance de la protection de vos données,{" "}
            <strong>AVEOGROUP</strong> est en conformité avec cette
            réglementation.
          </Section>
          <Section title="Cookies">
            Afin de faciliter l’utilisation du site, des cookies pourront être
            utilisés. Le cas échéant, ils pourront être désactivés via les
            options du navigateur.
          </Section>
        </article>
      </Box>
    </>
  );
};

export default Legale;
