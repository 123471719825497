import { Title } from "@components/Typography";
import { FC } from "react";
import chat from "@assets/img/chat.png";
import { services } from "@utils/constant";
import shadow from "@assets/img/shadow.svg";
import check from "@assets/img/check.png";
import check_white from "@assets/img/check-white.png";
import not from "@assets/img/not.png";
import illustr from "@assets/img/bg-service.png";
import Seo from "@utils/Seo";

const Service: FC = () => {
  return (
    <>
      <Seo title="Services" />
      <div className="p-5 pt-[2rem] md:p-[5rem] max-w-[1400px] m-auto">
        <div>
          <div className="flex justify-between">
            <Title Tag="h3" className="text-[24px] sm:text-[1.5rem]">
              Nos services et tarifs
            </Title>
            <img src={chat} alt="" className="w-[50px]" />
          </div>

          <p className="max-w-[750px] mb-5 mt-2">
            Vous souhaitez externaliser le traitement de votre service de
            support et avez besoin d’une solution de gestion de parc
            informatique, de ticketing et de prise en main à distance intégrée ?
          </p>
          <p>Nous proposons deux packs de service Helpdesk au choix.</p>
        </div>

        <div className="my-[5rem] mt-[2rem] flex flex-col md:flex-row gap-5">
          <div className="bg-primary text-white rounded-sm p-4 shadow-md">
            <Title
              Tag="h5"
              className="!text-[18px] uppercase !font-medium text-white text-center"
            >
              Plus
            </Title>
            {services.plus.details.map((dt, i) => {
              return (
                <p key={i} className="text-white text-center py-3">
                  {dt}
                </p>
              );
            })}
          </div>

          <div className="bg-white shadow-md rounded-sm p-4">
            <Title
              Tag="h5"
              className="!text-[18px] uppercase !font-medium  text-center"
            >
              standard
            </Title>
            {services.standard.details.map((dt, i) => {
              return (
                <p key={i} className=" text-center py-3">
                  {dt}
                </p>
              );
            })}
          </div>
        </div>

        <div className="bg-white p-1 sm:p-5 my-[5rem] rounded-sm">
          <table className="w-full">
            <thead>
              <tr>
                <th className="sm:w-1/2"></th>
                <th className="w-[200px]">
                  <p className="text-center sm:text-[1.2rem] py-2 uppercase font-medium">
                    Plus
                  </p>
                </th>
                <th className="bg-primary rounded-none w-[200px]">
                  <p className="text-white text-center sm:text-[1.2rem] py-2 uppercase font-medium">
                    Standard
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td className="py-3">
                  <p className="text-center font-medium sm:leading-[1]">
                    11 &euro; / h <br /> + <br /> Côut de la licence Kaseya
                  </p>
                </td>
                <td className="bg-primary rounded-none">
                  <p className="text-white text-center font-medium">
                    11 &euro; / h
                  </p>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <div className="flex flex-col items-center mb-2">
                    <img src={shadow} alt="" className="blur-[0.7px] h-[6px]" />
                  </div>
                </td>
                <td className="bg-primary rounded-none">
                  <div className="flex flex-col items-center mb-2">
                    <img src={shadow} alt="" className="blur-[0.7px] h-[6px]" />
                  </div>
                </td>
              </tr>
              {services.options.map((opt, i) => {
                return (
                  <tr key={i} className="hover:bg-gray-50 cursor-pointer group">
                    <td className="p-[20px] rounded-none">{opt}</td>
                    <td className="p-[20px] rounded-none">
                      <div className="flex justify-center">
                        <img
                          src={
                            services.plus.disable.indexOf(i) === -1
                              ? check
                              : not
                          }
                          alt=""
                          className="w-[25px]"
                        />
                      </div>
                    </td>
                    <td className="p-[20px] bg-primary rounded-none group-hover:bg-opacity-90">
                      <div className="flex justify-center">
                        <img
                          src={
                            services.standard.disable.indexOf(i) === -1
                              ? check_white
                              : not
                          }
                          alt=""
                          className="w-[25px]"
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="relative">
          <Title Tag="h6" className="!text-[17px] !font-bold">
            LE LOGICIEL KASEYA{" "}
          </Title>
          <p>
            Nous avons choisi le logiciel KASEYA avec{" "}
            <strong>l'offre Support Plus</strong> en raison de ses
            fonctionnalités très riches :
          </p>
          <ul className="list-disc ml-8 mt-3">
            {services.kaseya.map((ks, i) => {
              return <li key={i}>{ks}</li>;
            })}
          </ul>
          <img
            src={illustr}
            alt=""
            className="hidden sm:block absolute bottom-0 right-0 w-[300px] -z-10"
          />
        </div>
      </div>
    </>
  );
};

export default Service;
