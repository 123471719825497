import { FC, ReactNode } from "react";
import { motion } from "framer-motion";

interface TransitionProps {
  children: ReactNode;
  animation?: "fade" | "slide-t" | "rotate";
  className?: string;
  duration?: number;
  repeat?: any;
}

const Transition: FC<TransitionProps> = ({
  children,
  animation = "fade",
  className,
  duration = 1.5,
  repeat = 0,
}) => {
  const anim: any = {
    fade: {
      initial: { opacity: 0 },
      animate: { opacity: 1 },
      exit: { opacity: 0 },
    },
    rotate: {
      initial: { rotate: 0 },
      animate: { rotate: 360 },
      exit: { rotate: 0 },
    },
    "slide-t": {
      initial: { translateY: 100 },
      animate: { translateY: 0 },
      exit: { translateY: 100 },
    },
  };
  return (
    <motion.div
      className={className}
      initial={anim[animation].initial}
      animate={anim[animation].animate}
      exit={anim[animation].exit}
      transition={{ repeat, duration }}
    >
      {children}
    </motion.div>
  );
};

export default Transition;
