import { FC } from "react";
import Value from "@containers/Value";
import Why from "@containers/Why";
import ContactBox from "@containers/ContactBox";
import Reference from "@containers/Reference";
import About from "@containers/About";
import Welcome from "@containers/Welcome";
import Seo from "@utils/Seo";

const Home: FC = () => {
  return (
    <>
      <Seo title="Accueil" />
      <Welcome />
      <About />
      <Value />
      <Why />
      <Reference />
      <ContactBox />
    </>
  );
};

export default Home;
