export const sendMail = (
  email: string,
  phone: string,
  subject: string,
  message: string,
  callback: (status: number) => void
) => {
  const http = new XMLHttpRequest();
  http.onreadystatechange = (e) => {
    if (http.readyState === 4) callback(http.status);
  };
  http.open("POST", "https://api.aveodesk.com/", true);
  http.setRequestHeader("content-type", "application/x-www-form-urlencoded");
  http.send(
    `email=${email}&phone=${phone}&subject=${subject}&message=${message}`
  );
};
